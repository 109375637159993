import React from 'react';
import Navbar from '../components/navbar/Navbar';
import {Message as Message} from './comingSoonComponents';

const NotFound = () => {
    return (
        <div>
            <Navbar />
            <Message>Page Not Found</Message>
        </div>
    );
};
 
export default NotFound;