import React from 'react'
import {useState} from 'react';
import {ContinueButton, CustomizeReadingButton, QuestionTextBox, QuestionPromptMessage} from './AskQuestionOverlayComponents';

const AskQuestionOverlay = (props) => {
  const [query, setQuery] = useState();

  return (
    <div>
      <QuestionPromptMessage>What do you want to know?</QuestionPromptMessage>
      <QuestionTextBox
          name="question"
          maxLength={300}
          placeholder='Ask your question here'
          onChange={(e) => setQuery(e.target.value)}
        />
      {/* This button removed until feature implemented: <CustomizeReadingButton>Customize Reading</CustomizeReadingButton>*/}
      <ContinueButton onClick={() => {props.showNextMenu(query)}}>Continue</ContinueButton>
    </div>
  )
}

export {AskQuestionOverlay};