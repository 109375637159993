import React from 'react'
import { useState } from 'react';
import useFetch from '../../../useFetch';
import {Spinner, LoadingResultsContainer, CardsBox, Card, Message, ResultsOverlayContainer, ResultsPromptMessage, ResultsText, FinishReadingButton} from './ResultsOverlayComponents';
import {get_tarot_url} from "../../../endpoints";

const ResultsOverlay = (props) => {
  let {isPending, error, data } = useFetch(get_tarot_url, props.query)
  const [showReadingText, setShowReadingText] = useState();
  if (isPending){
    return (
      <LoadingResultsContainer>
        <Message>Consulting the cards...</Message>
        <Spinner/>
      </LoadingResultsContainer>
    )
  }

  if(data){
    if(!showReadingText){
      return (
        <ResultsOverlayContainer>
          <ResultsPromptMessage>These are your cards:</ResultsPromptMessage>
            <CardsBox>
              <Card alt={data.card1.name} src={data.card1.image}/>
              <Card alt={data.card2.name} src={data.card2.image}/>
              <Card alt={data.card3.name} src={data.card3.image}/>
            </CardsBox>
          <FinishReadingButton onClick={setShowReadingText}>Continue</FinishReadingButton>
        </ResultsOverlayContainer>
      )
    }
    return (
      <ResultsOverlayContainer>
        <ResultsPromptMessage>This is what the cards say:</ResultsPromptMessage>
        <ResultsText>{data.message}</ResultsText>
        <FinishReadingButton onClick={props.showNextMenu}>Finish</FinishReadingButton>
      </ResultsOverlayContainer>
      
    )
  }
  return (
    <ResultsOverlayContainer>
        <ResultsPromptMessage>Your future could not be determined</ResultsPromptMessage>
        <ResultsText>There are many reasons this could occur. Please try again later.</ResultsText>
        <FinishReadingButton onClick={props.showNextMenu}>Finish</FinishReadingButton>
      </ResultsOverlayContainer>
  )
}

export {ResultsOverlay};
