import React from 'react'
import {WelcomeOverlay} from './readingInterfaceOverlays/WelcomeOverlay';
import { AskQuestionOverlay } from './readingInterfaceOverlays/AskQuestionOverlay';
import { ResultsOverlay } from './readingInterfaceOverlays/ResultsOverlay';
import { useState } from 'react';

const ReadingInterface = () => {
  const [isWelcome, setIsWelcome] = useState(true);
  const [isAskQuestion, setIsAskQuestion] = useState(false);
  const [isResults, setIsResults] = useState(false);
  const [query, setQuery] = useState("");

  function showNextMenu(newQuery=null){
    if(isWelcome){
      setIsWelcome(false);
      setIsAskQuestion(true);
    }
    if(isAskQuestion){
      setIsAskQuestion(false);
      setIsResults(true);
      setQuery(newQuery);
    }
    if(isResults){
      setIsResults(false);
      setIsWelcome(true);
    }
    
  }

  return (
    <div>
      {isWelcome && <WelcomeOverlay showNextMenu={showNextMenu}/>}
      {isAskQuestion && <AskQuestionOverlay setQuery={setQuery} showNextMenu={showNextMenu}/>}
      {isResults && <ResultsOverlay query={query} showNextMenu={showNextMenu}/>}
    </div>
  )
}

export default ReadingInterface;