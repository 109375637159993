import styled from "styled-components";

export const SceneContainer = styled.div`
    position: relative; 
    width: 100vw;
    height: 100vh;
    overflow: hidden;
`;

export const JadisStill = styled.img`
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 90vh;
    height: 90vh;
    background: lightgray 50% / cover no-repeat;
    @media (orientation: portrait) {
        margin-top: 10%;
        height: 60vh;
        width: 60vh;
    }        
`;

export const RadialGradient = styled.div`
    position: absolute;    
    left: 50%;
    transform: translate(-50%, 0);  
    width: 115vh;
    height: 100vh;
    background: radial-gradient(50% 45.93% at 50% 50.00%, rgba(8, 12, 20, 0.00) 0%, rgba(8, 12, 20, 1.00) 100%);
    @media (orientation: portrait) {
        background: radial-gradient(40% 40.93% at 50% 50.00%, rgba(8, 12, 20, 0.00) 0%, rgba(8, 12, 20, 1.00) 100%);
        margin-top: 10%;
        height: 60vh;
        width: 60vh;
    }    
`;

export const LeftLinGrad = styled.div`
    position: absolute;   
    width: 33vw;
    height: 100vh;
    left: 0px;
    top: 0px;
    background: linear-gradient(90deg, rgba(8, 12, 20, 0.00) 0%, rgba(8, 12, 20, 0.78) 67.05%, rgba(8, 12, 20, 0.00) 100%);
`;

export const RightLinGrad = styled.div`
    position: absolute;    
    width: 33vw;
    height: 100vh;
    right: 0px;
    top: 0px;
    background: linear-gradient(270deg, rgba(8, 12, 20, 0.00) 0%, rgba(8, 12, 20, 0.78) 67.05%, rgba(8, 12, 20, 0.00) 100%);
`;

export const Fog = styled.img`
    position: absolute;
    width: 100vw;
    bottom: -50px;
    @media (orientation: portrait) {
        bottom: 0px;
        height: 40vh;
    }  
`;
