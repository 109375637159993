import React from 'react';
import Scene from '../components/scene/Scene';
import Navbar from '../components/navbar/Navbar';
import styled from "styled-components";
import ReadingInterface from '../components/readingInterface/ReadingInterface';

const Home = () => {
    return (
        <div>
            <ReadingInterface/>
            <Scene>  
            </Scene>
            <Navbar />
        </div>
    );
};
 
export default Home;