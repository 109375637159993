import styled from "styled-components";

export const StartReadingButton = styled.button`
    position: absolute;
    z-index: 1;
    width: 344px;
    height: 76px;
    left: 50%;
    transform: translate(-50%, 0);
    top: 83%;
    max-width: 50%;
    color: #936250;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 72.727% */
    text-transform: uppercase;
    border-radius: 6px;
    cursor: pointer;
    border: 2px solid #936250;
    background: radial-gradient(50.00% 50.75% at 50.00% 49.25%, rgba(147, 98, 80, 0.00) 35.42%, rgba(147, 98, 80, 0.08) 100%);
    &:hover {
        background-color: #b88b7a30;
    }
    @media (orientation: portrait) {
        margin-top: 5px;
    }
    @media (orientation: landscape) {
        margin-top: 20px;
    }
`;
