import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages';
import Forum from './pages/forum';
import Blog from './pages/blog';
import NotFound from './pages/notFound';
import {Message as Message} from './pages/comingSoonComponents';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

let firebaseConfig;

if (process.env.REACT_APP_ENVIRONMENT == "SANDBOX"){
    firebaseConfig = {
        apiKey: "AIzaSyDVimF_-INdie2Wwz6vGtzsj7WgChZm-lE",
        authDomain: "mytarotaisandbox.firebaseapp.com",
        projectId: "mytarotaisandbox",
        storageBucket: "mytarotaisandbox.appspot.com",
        messagingSenderId: "818010216120",
        appId: "1:818010216120:web:a83fe1525beb9588f5864d",
    };
} else if (process.env.REACT_APP_ENVIRONMENT == "PROD"){
    firebaseConfig = {
        apiKey: "AIzaSyDAwHxbRIMy1QKw0H3hb-sLhVtxHLIC7K0",
        authDomain: "mytarotai-prod.firebaseapp.com",
        projectId: "mytarotai-prod",
        storageBucket: "mytarotai-prod.appspot.com",
        messagingSenderId: "275230576349",
        appId: "1:275230576349:web:805d5481f805481910d534",
    };
};

  
firebase.initializeApp(firebaseConfig);

const uiConfig = {
    signInFlow: 'popup',
    // Redirect to home after sign in succeeds
    signInSuccessUrl: '/',

    signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    tosUrl: 'https://www.google.com',
    privacyPolicyUrl: 'https://www.google.com',
};
 
function App() {
    const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
    
    useEffect(() => { // Listen to the Firebase Auth state and set the local state.
        const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    if (!isSignedIn) { //Only the login page is available if the user is not logged in
        return (
            <div>
                <Message style={{top: '20%'}}>Login</Message>
                <div style={{height: '25vh', width: '85%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
                    {firebase && <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />}
                </div> 
            </div>
        );
    } else {
        return ( //Once logged in, the routes are accessible
            <Router>
                <Routes>
                    <Route exact path='/' element={<Home />} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path='/forum' element={<Forum />} />
                    <Route path='*' element={<NotFound />} />
                </Routes>
            </Router>
        );
    }
}
 
export default App;
