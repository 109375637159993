import React from 'react'
import {StartReadingButton} from './WelcomeOverlayComponents';
import {PromptMessage} from '../ReadingInterfaceComponents';

const WelcomeOverlay = (props) => {
  return (
    <div>
      <PromptMessage>Hello. My name is Jadis. I can predict your future...</PromptMessage>
      <StartReadingButton onClick={props.showNextMenu}>Start a Tarot Reading</StartReadingButton>
    </div>
  )
}

export {WelcomeOverlay};