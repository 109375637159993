import React from 'react';
import styled from "styled-components";
import {JadisStill, SceneContainer,RadialGradient,LeftLinGrad,RightLinGrad,Fog} from "./SceneComponents";

const Scene = () => {
  return (
    <SceneContainer>
        <JadisStill src='/assets/jadis.png' />
        <RadialGradient></RadialGradient>
        <LeftLinGrad></LeftLinGrad>
        <RightLinGrad></RightLinGrad>
        <Fog src='/assets/fog.png' />
    </SceneContainer>
  )
}

export default Scene;