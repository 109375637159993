import { NavLink as Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
 
export const NavbarWrapper = styled.nav`
  width: 100%;
  height: 40px;
  flex-shrink: 0;
  z-index: 12;
  display: flex;
  justify-content: space-between;
  margin-top: 34px;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.3em;
  @media screen and (max-width: 768px) {
    background: rgba(8, 12, 20, 0.5);
    height: ${(props) => (props.$isNavExpanded ? "35vh" : "40px")};
    
  }
  @media screen and (min-width: 768px) {
    background: none;

  }
`;

export const Hamburger = styled.div`
  color: #936250;
  display: none;
  position: absolute;
  top: 17px;
  right: 15px;
  scale: 200%;
  transform: translate(-100%, 0%);
  font-size: 2rem;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: block;
    
  }
`;
 
export const NavLink = styled(Link)`
  color: #936250;
  font-family: Poppins;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 100% */
  text-decoration: none;
  margin-left: 40px;
  margin-right: 40px;
  cursor: pointer;
  display: flex;
  &.active {
    text-decoration: underline;
  }
  &:hover {
    transition: all 0.15s ease-in-out;
    color: #b88b7a;
  }
  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
`;

export const SiteTitle = styled.div`
  display: none;
  color: #936250;
  font-family: Yeseva One;
  font-size: 1.4em;
  position: absolute;
  top: 15px;
  left: 40px;
  transform: translate(0, 0%);
  @media screen and (max-width: 768px) {
    display: block;
  }
`;
 
export const NavMenu = styled.div`
  
  height: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  
  @media screen and (max-width: 768px) {
    
    display: ${(props) => (props.$isNavExpanded ? "block" : "none")};
    transform: translate(0, ${(props) => (props.$isNavExpanded ? "85px" : "-85px")});
    
  }
`;

export const SignOut = styled.button`
    position: absolute;
    z-index: 1;
    right: 50px;
    padding: 5px;
    color: #936250;
    font-family: Poppins;
    background-color: transparent;
    margin-left: 40px;
    margin-right: 40px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 72.727% */
    border-radius: 6px;
    cursor: pointer;
    border: 2px solid #936250;
    &:hover {
        background-color: #b88b7a30;
    }
    @media screen and (max-width: 768px) {
        position: relative;
        right: 0px;
        margin-top: 80px;
    }
`;
