import styled from 'styled-components';

export const Message = styled.h1`
    font-size: 5rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    color: #936250;
    font-family: Yeseva One;

`;
