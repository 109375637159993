import styled from "styled-components";
import { PromptMessage } from "../ReadingInterfaceComponents";

export const ResultsOverlayContainer = styled.div`
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 100%;
    width: 800px;
    height: 570px;
    max-width: 80%;
    max-height: 65%;
    border-radius: 8px;
    border: 3px solid #936250;
    background: linear-gradient(to top, rgba(8, 12, 20, .2), rgba(8, 12, 20, 1) 40%);
    transform: translate(-50%, -103%);
    @media (max-width: 768px) {
        max-height: 40%;
    }
`;

export const LoadingResultsContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.300);
    z-index: 1;
`;

export const Spinner = styled.div`
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: white transparent white transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
    @keyframes spin-anim {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
`;

export const ResultsPromptMessage = styled(PromptMessage)`
    font-size: 28px;
    position: relative;
    width: 65%;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0);
    @media (orientation: portrait) {
        width: 90%;
        font-size: 18px;
        left: 50%;
        transform: translate(-50%, 0);
        line-height: 30px;
`;

export const ResultsText = styled.div`
    position: relative;
    width: 83%;
    height: 40%;
    margin: 0 auto;
    background: #111621;
    border-radius: 8px;
    overflow: scroll;
    padding: 30px;
    color: rgba(255, 255, 255, 0.80);
    font-family: Yeseva One;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 185.714% */
    
    &::-webkit-scrollbar {
        width: 10px;
        
    }
    &::-webkit-scrollbar-corner {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        border: 3px solid #111621;
        background: #93625050;
    }
    @media (max-width: 768px) {
        width: 67%;
        height: 30%;
    }
`;

export const CardsBox = styled.div`
    position: relative;
    width: 83%;
    height: 40%;
    margin: 0 auto;
    background: #111621;
    border-radius: 8px;
    overflow: scroll;
    padding: 30px;
    color: rgba(255, 255, 255, 0.80);
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 26px; /* 185.714% */

    &::-webkit-scrollbar {
        width: 10px;
        
    }
    &::-webkit-scrollbar-corner {
        display: none;
    }
    &::-webkit-scrollbar-thumb {
        border: 3px solid #111621;
        background: #93625050;
    }
    @media (max-width: 768px) {
        width: 67%;
        height: 49%;
    }
`;

export const FinishReadingButton = styled.button`
    position: absolute;
    z-index: 1;
    width: 175px;
    height: 60px;
    right: 35px;
    top: 83%;
    color: #936250;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 72.727% */
    text-transform: uppercase;
    border-radius: 6px;
    cursor: pointer;
    border: 2px solid #936250;
    background: radial-gradient(50.00% 50.75% at 50.00% 49.25%, rgba(147, 98, 80, 0.00) 35.42%, rgba(147, 98, 80, 0.08) 100%);
    &:hover {
        background-color: #b88b7a30;
    }
    @media (max-width: 768px) {
        width: 125px;
        height: 50px;
        font-size: 18px;
        top: 81%;
        right: 28px;
    }

`;
export const Message = styled.h1`
    font-size: 30px;
    color: white;
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translate(-50%, -50%);
    font-family: Poppins;

`;

export const Card = styled.img`
    margin: 0px 25px;
    width: 23%;
    
    @media (max-width: 768px) {
        width: 65%;
        margin: 5px 5px;

    } 
`;
