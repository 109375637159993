import React from 'react';
import Navbar from '../components/navbar/Navbar';
import {Message as Message} from './comingSoonComponents';

const Forum = () => {
    return (
        <div>
            <Navbar />
            <Message>Forum coming soon!</Message>
        </div>
    );
};
 
export default Forum;