import { useState, useEffect } from 'react';
import firebase from 'firebase/compat/app';

const useFetch = (url, query) => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(false);
  
  useEffect(() => {
    firebase.auth().currentUser.getIdToken(true).then(function(token){
      fetch(url, {
        headers: {
          'Authorization': `Bearer ` + token,
          'Content-Type': "application/json"
        }, 
        method: "POST", 
        body: JSON.stringify({message: query})
      })
      .then(res => {
        return res.json();
      })
      .then(data => {
        setData(data);
        setError(false);
        setIsPending(false);
      })
      .catch(err => {
        setError(true);
        setIsPending(false);
      })
    });
  }, [url, query]);

  return { data, isPending, error };
};
 
export default useFetch;