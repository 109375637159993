import styled from "styled-components";

export const PromptMessage = styled.p`
    color: #FFF;
    text-align: center;
    font-family: Yeseva One;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 48px; /* 133.333% */
    width: 754px;
    max-width: 85vw;
    top: 68%;
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    z-index: 1;
    @media (orientation: portrait) {
        top: 58%;
        font-size: 33px;   
    }
`;
